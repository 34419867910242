// crs组前缀
const crsPrefix = '/crs_api';
// 直销组前缀
const rezenPrefix = '/api';

const crsApiMapping = {
  // 获取会员来源options
  getMemberJointypeList: `${crsPrefix}/merchant/lps/memberjointype/getMemberJointypeList`,
  // 保存会员等级
  saveLpsMemberRank: `${crsPrefix}/merchant/lps/memberrank/saveLpsMemberRank`,
  // 新增活动积分策略
  saveActivityPointpolicy: `${rezenPrefix}/merchant/lps/member/saveActivityPointpolicy`,
  // 修改活动积分策略
  updateActivityPointpolicy: `${rezenPrefix}/merchant/lps/member/updateActivityPointpolicy`,
  // 获取会员来源列表
  queryAvaliableMemberJointypeList: `${crsPrefix}/merchant/lps/memberjointype/queryAvaliableMemberJointypeList`,
  // 切换会员来源显示标记
  switchMemberJointypeDisplayFlag: `${crsPrefix}/merchant/lps/memberjointype/switchMemberJointypeDisplayFlag`,
  // 获取会员升降级保级策略详情
  getLpsUpgradepolicy: `${rezenPrefix}/merchant/lps/upgradepolicy/getLpsUpgradepolicy`,
  // POST 获取一条消费的明细
  queryConsumeDetailList: `${crsPrefix}/merchant/lps/consume/queryConsumeDetailList`,
  // 获取CRS渠道列表
  getCrsChannelList: `${crsPrefix}/merchant/crs/channel/getCrsChannelList`,
};

const rezenApiMapping = {
  // 获取操作员列表
  queryStaffByDisplayName: `${rezenPrefix}/staff/queryStaffByDisplayName`,
  // 获取卡券列表
  getLichengCoupon: `${rezenPrefix}/merchant/product/lcCoupon/getLichengCoupon`,
  // 设置卡券状态
  updateLichengCouponStatus: `${rezenPrefix}/merchant/product/lcCoupon/updateLichengCouponStatus`,
  // 新增卡券
  createLichengCoupon: `${rezenPrefix}/merchant/product/lcCoupon/createLichengCoupon`,
  // 获取酒店详情
  getHotelInfoByGroupId: `${rezenPrefix}/merchant/product/hotel/getHotelInfoByGroupId`,
  // 获取国家列表
  getCountryList: `${rezenPrefix}/district/getCountryList`,
  // 卡券使用记录
  getGroupRecord: `${rezenPrefix}/merchent/couponUseRecord/getGroupRecord`,
  // 卡券查询
  showCouponList: `${rezenPrefix}/personalCenter/v2/userCoupon/showCouponList`,
  getUserCouponForMerchant: `${rezenPrefix}/personalCenter/v2/userCoupon/getUserCouponForMerchant`,
  // 无效卡券
  invalidCoupon: `${rezenPrefix}/personalCenter/v2/userCoupon/invalidCoupon`,
  invalidUserCouponForMerchant: `${rezenPrefix}/personalCenter/v2/userCoupon/invalidUserCouponForMerchant`,
  // 获取会员提示信息
  getCouponMemberRightHint: `${rezenPrefix}/merchant/product/lcCoupon/getCouponMemberRightHint`,
  // 编辑会员提示信息
  editCouponMemberRightHint: `${rezenPrefix}/merchant/product/lcCoupon/editCouponMemberRightHint`,
  // 获取操作单位下拉框和列表数据
  listUnit: `${rezenPrefix}/unit/listUnit`,
  // 获取会员权益
  getMemberRightByMemberLevel: `${rezenPrefix}/membermanagement/getMemberRightByMemberLevel`,
  // 修改会员权益
  updateMemberGradeData: `${rezenPrefix}/membermanagement/updateMemberGradeData`,
  // 酒店端获取会员权益使用
  getHotelRecord: `${rezenPrefix}/merchent/couponUseRecord/getHotelRecord`,
  // 下载卡券使用记录汇总
  downloadGroupSummaryCouponUserRecord: `${rezenPrefix}/merchent/couponUseRecord/downloadGroupSummaryCouponUserRecord`,
  // 下载卡券使用记录
  downloadCouponUseRecord: `${rezenPrefix}/merchent/couponUseRecord/downloadCouponUseRecord`,
  // 查询会员列表
  queryLpsBnsMemberList: `${rezenPrefix}/merchant/lps/member/queryLpsBnsMemberList`,
  // 查询会员详情
  getLpsMember: `${rezenPrefix}/merchant/lps/member/getLpsMember`,
  // 设置支付状态
  setPayStatus: `${rezenPrefix}/merchant/lps/member/setPayStatus`,
  // 设置会员状态
  setMemberStatus: `${rezenPrefix}/merchant/lps/member/setMemberStatus`,
  // 获取会员积分明细
  queryLpsPointTradeListByMember: `${rezenPrefix}/merchant/lps/point/queryLpsPointTradeListByMember`,
  // 积分转出验证码发送
  pointMoveOutSmsSend: `${rezenPrefix}/merchant/lps/point/pointMoveOutSmsSend`,
  // 积分转出验证码验证
  pointMoveOutSmsVerificate: `${rezenPrefix}/merchant/lps/point/pointMoveOutSmsVerificate`,
  // 获取会员积分间夜基础信息
  getLpsMemberPointBase: `${rezenPrefix}/merchant/lps/member/getLpsMemberPointBase`,
  // 积分转入验证码发送
  pointMoveInSmsSend: `${rezenPrefix}/merchant/lps/point/pointMoveInSmsSend`,
  // 会员积分间夜转移
  memberPointAndNightMove: `${rezenPrefix}/merchant/lps/point/memberPointAndNightMove`,
  // 积分间夜补录验证码发送
  pointAndNightMoveSmsSend: `${rezenPrefix}/merchant/lps/point/pointAndNightMoveSmsSend`,
  // 手工补充积分和间夜
  addPointAndNightManual: `${rezenPrefix}/merchant/lps/point/addPointAndNightManual`,
  // 会员会员充值使用明细列表
  queryStoreTrade: `${rezenPrefix}/merchant/lps/storeTrade/queryStoreTrade`,
  // 查询充值包配置列表
  queryStorePolicy: `${rezenPrefix}/merchant/lps/storePolicy/queryStorePolicy`,
  // 获取会员等级
  getAllMemberRank: `${rezenPrefix}/merchant/lps/memberrank/getAllMemberRank`,
  // 获取会员联盟会员信息
  getMemberUnionMembersInfo:`${rezenPrefix}/lps/unionMember/getMemberUnionMembersInfo`,
   // 获取会员画像
  getMemberUserProfilingLable: `${rezenPrefix}/merchant/member/label/getMemberUserProfilingLable`,
  // 获取充值包详情
  getStorePolicy: `${rezenPrefix}/merchant/lps/storePolicy/getStorePolicy`,
  // 编辑充值包
  addOrUpdateStorePolicy: `${rezenPrefix}/merchant/lps/storePolicy/addOrUpdateStorePolicy`,
  // 修改充值包状态
  updateStorePolicyStatus: `${rezenPrefix}/merchant/lps/storePolicy/updateStorePolicyStatus`,
  // 获取会员提示信息
  queryMemberPrompt: `${rezenPrefix}/merchant/lps/memberPrompt/queryMemberPrompt`,
  // 修改会员提示信息
  addOrUpdateMemberPrompt: `${rezenPrefix}/merchant/lps/memberPrompt/addOrUpdateMemberPrompt`,
  // 重置支付密码
  resetPayPwd: `${rezenPrefix}/merchant/lps/member/resetPayPwd`,
  // 获取会员升降级原因
  queryMemberUpgradeReasonList: `${rezenPrefix}/merchant/lps/member/queryMemberUpgradeReasonList`,
  // 手动升降级
  manualUpdLpsBnsMemberlevel: `${rezenPrefix}/merchant/lps/member/manualUpdLpsBnsMemberlevel`,
  // 获取所有证件类型列表
  queryCertificateList: `${rezenPrefix}/merchant/lps/profile/queryCertificateList`,
  // POST 通过客史号获取客史信息列表
  getProfileShowListById: `${rezenPrefix}/merchant/lps/profile/getProfileShowListById`,
  // 获取客史显示列表
  queryProfileShowList: `${rezenPrefix}/merchant/lps/profile/queryProfileShowList`,
  // POST 设置会员对应客史
  setMemberProfile: `${rezenPrefix}/merchant/lps/member/setMemberProfile`,
  // POST 会员映射客史次数清零
  clearMemberProfileMappingTimes: `${rezenPrefix}/merchant/lps/member/clearMemberProfileMappingTimes`,
  // 获取会员导入日志
  getLpsMemberImportLog: `${rezenPrefix}/merchant/lps/memberimport/getLpsMemberImportLog`,
  // 获取会员导入模板
  getLpsMemberImportTemplate: `${rezenPrefix}/merchant/lps/memberimport/getLpsMemberImportTemplate`,
  // 会员导入
  lpsMemberImport: `${rezenPrefix}/merchant/lps/memberimport/lpsMemberImport`,
  // 获取会员导入进度
  getLpsMemberImportProgerss: `${rezenPrefix}/merchant/lps/memberimport/getLpsMemberImportProgerss`,
  // 获取会员导入失败文件
  getMemberImportFailedFile: `${rezenPrefix}/merchant/lps/memberimport/getMemberImportFailedFile`,
  // 设置默认会员等级
  setDefaultMemberRank: `${rezenPrefix}/merchant/lps/memberrank/setDefaultMemberRank`,
  // 获取会员等级图标
  queryMemberRankIconList: `${rezenPrefix}/merchant/lps/memberrank/queryMemberRankIconList`,
  // 获取会员等级详情
  getMemberRankById: `${rezenPrefix}/merchant/lps/memberrank/getMemberRankById`,
  // 修改会员等级
  updateLpsMemberRank: `${rezenPrefix}/merchant/lps/memberrank/updateLpsMemberRank`,
  // 获取crs积分项目列表
  queryConsumeItemList: `${rezenPrefix}/merchant/lps/pointpolicy/queryConsumeItemList`,
  // 获取crs积分项目
  getConsumeItemByCode: `${rezenPrefix}/merchant/lps/pointpolicy/getConsumeItemByCode`,
  // 修改积分项目
  modifyConsumeItem: `${rezenPrefix}/merchant/lps/pointpolicy/modifyConsumeItem`,
  // 获取积分项目状态
  setConsumeItemStatus: `${rezenPrefix}/merchant/lps/pointpolicy/setConsumeItemStatus`,
  // 新增积分项目
  newConsumeItem: `${rezenPrefix}/merchant/lps/pointpolicy/newConsumeItem`,
  // POST 获取消费来源列表
  getOrderSourceList: `${rezenPrefix}/merchant/lps/pointpolicy/getOrderSourceList`,
  // 获取积分策略列表
  queryPointpolicyList: `${rezenPrefix}/merchant/lps/pointpolicy/queryPointpolicyList`,
  // 获取积分策略
  getPointpolicy: `${rezenPrefix}/merchant/lps/pointpolicy/getPointpolicy`,
  // 修改积分策略
  updatePointPolicy: `${rezenPrefix}/merchant/lps/pointpolicy/updatePointPolicy`,
  // 设置积分策略状态
  setPointPolicyStatus: `${rezenPrefix}/merchant/lps/pointpolicy/setPointPolicyStatus`,
  // 新增积分策略
  savePointPolicy: `${rezenPrefix}/merchant/lps/pointpolicy/savePointPolicy`,
  // 查询会员升降级保级策略列表
  queryLpsUpgradepolicyList: `${rezenPrefix}/merchant/lps/upgradepolicy/queryLpsUpgradepolicyList`,
  // 新建会员升降级保级策略
  saveLpsUpgradepolicy: `${rezenPrefix}/merchant/lps/upgradepolicy/saveLpsUpgradepolicy`,
  // 启动会员升降级保级策略
  recoverLpsUpgradepolicy: `${rezenPrefix}/merchant/lps/upgradepolicy/recoverLpsUpgradepolicy`,
  // 禁用会员升降级保级策略
  disableLpsUpgradepolicy: `${rezenPrefix}/merchant/lps/upgradepolicy/disableLpsUpgradepolicy`,
  // 修改会员升降级保级策略
  updateLpsUpgradepolicy: `${rezenPrefix}/merchant/lps/upgradepolicy/updateLpsUpgradepolicy`,
  // 获取客史消费列表
  queryProfileConsumeList: `${rezenPrefix}/merchant/lps/profile/queryProfileConsumeList`,
  // 获取客史信息
  getProfileById: `${rezenPrefix}/merchant/lps/profile/getProfileById`,
  // POST 保存客史信息
  saveProfile: `${rezenPrefix}/merchant/lps/profile/saveProfile`,
  // 获取充值使用明细
  getStoreTrade: `${rezenPrefix}/merchant/lps/storeTrade/getStoreTrade`,
  // 导出会员充值使用明细记录
  exportStoreTrade: `${rezenPrefix}/merchant/lps/storeTrade/exportStoreTrade`,
  // POST获取所有民族列表
  queryNationList: `${rezenPrefix}/merchant/lps/profile/queryNationList`,
  getProductByProductName: `${rezenPrefix}/goods/credit/getProductByProductName`, // 根据名称搜索产品列表
  getProductsByMemberLevelCode: `${rezenPrefix}/goods/credit/getProductsByMemberLevelCode`, // 根据ID获取产品列表
  operatingProduct: `${rezenPrefix}/member/benefit/operatingProduct`, // 兑换产品操作类接口
  getMemberRightPriceConfigByLevelId: `${rezenPrefix}/memberManage/getMemberRightPriceConfigByLevelId`, // 获取配置的会员价格
  updateMemberRightPriceConfig: `${rezenPrefix}/memberManage/updateMemberRightPriceConfig`, // 获取配置的会员价格
  updateMemberInfo: `${rezenPrefix}/merchant/lps/member/updateMemberInfo`, // 修改会员基本信息
  unbundling: `${rezenPrefix}/merchant/lps/member/unbundling`, // 会员解绑
  unbundlingSmsSend: `${rezenPrefix}/merchant/lps/member/unbundlingSmsSend`, // 会员解绑发送短信
  checkMemberIfCanCancel: `${rezenPrefix}/merchant/lps/member/checkMemberIfCanCancel`, // 校验会员是否能注销
  getMemberRightRuleConfigByLevelId: `${rezenPrefix}/membermanagement/getMemberRightRuleConfigByLevelId`, // 获取当前等级的权益券配置
  getAllLevelMemberRightRuleConfig: `${rezenPrefix}/membermanagement/getAllLevelMemberRightRuleConfig`, // 获取所有等级权益券配置
  manualGiveUserRightCoupon: `${rezenPrefix}/membermanagement/manualGiveUserRightCoupon`, // 手动发放权益券
  queryNightsDetail: `${rezenPrefix}/merchant/lps/point/queryNightsDetail`, // 获取会员间夜详情
  queryLevelChangeDetail: `${rezenPrefix}/merchant/lps/point/queryLevelChangeDetail`, // 获取会员间夜详情

  getUnselectedHotelList: `${rezenPrefix}/merchant/product/hotel/getUnselectedHotelList`, // 活动积分策略获取酒店列表
  getCityList: `${rezenPrefix}/district/getCityList`, // 活动积分策略获取城市列表
  getProvinceList: `${rezenPrefix}/district/getProvinceList`, // 活动积分策略获取省市列表
  getBrandList: `${rezenPrefix}/merchant/product/hotel/getBrandList`, // 活动积分策略获取品牌列表
  getMemberBatchUpgradeImportTemplate: `${rezenPrefix}/merchant/lps/batchUpgrade/getMemberBatchUpgradeImportTemplate`, // 获取会员批量升级模板
  getMemberBatchUpgradeImportLog: `${rezenPrefix}/merchant/lps/batchUpgrade/getMemberBatchUpgradeImportLog`, // 获取会员批量升级日志
  memberBatchUpgradeImport: `${rezenPrefix}/merchant/lps/batchUpgrade/memberBatchUpgradeImport`, // 会员批量升级导入
  getMemberBatchUpgradeImportFailedFile: `${rezenPrefix}/merchant/lps/batchUpgrade/getMemberBatchUpgradeImportFailedFile`, // 会员批量升级导入失败文件
  getMemberBatchUpgradeImportProgerss: `${rezenPrefix}/merchant/lps/batchUpgrade/getMemberBatchUpgradeImportProgerss`, // 会员批量升级导入进度
  queryMemberExperienceRankRecord: `${rezenPrefix}/merchant/lps/member/queryMemberExperienceRankRecord`, // 体验会员详情
  getMemberMemberRightCouponBatchSendTemplate: `${rezenPrefix}/merchant/member/batchSend/getMemberMemberRightCouponBatchSendTemplate`, // 会员权益券批量发放
  memberRightCouponBatchSendImport: `${rezenPrefix}/merchant/member/batchSend/memberRightCouponBatchSendImport`, // 会员权益券批量发送导入
  getMemberRightCouponBatchSendLog: `${rezenPrefix}/merchant/member/batchSend/getMemberRightCouponBatchSendLog`, // 获取会员权益前批量发放记录
  getMemberRightCouponBatchSendFailLog: `${rezenPrefix}/merchant/member/batchSend/getMemberRightCouponBatchSendFailLog`, // 获取批量发送会员权益券失败文件
  getemberRightCouponBatchSendImportProgerss: `${rezenPrefix}/merchant/member/batchSend/getemberRightCouponBatchSendImportProgerss`, // 获取批量发送会员权益券进度
  downLoadFailedList: `${rezenPrefix}/merchant/lps/memberLevel/extendTime/downLoadFailedList`, // 会籍延期=》下载失败列表
  getExtendTimeList: `${rezenPrefix}/merchant/lps/memberLevel/extendTime/getExtendTimeList`, // 会籍延期=获取会员级别延期列表
  saveExtendTimeByLevel: `${rezenPrefix}/merchant/lps/memberLevel/extendTime/saveExtendTimeByLevel`, // 会籍延期=保存延期时间根据会员等级
  saveExtendTimeByMemberNo: `${rezenPrefix}/merchant/lps/memberLevel/extendTime/saveExtendTimeByMemberNo`, // 会籍延期=保存延期时间个人
  templateDownload: `${rezenPrefix}/merchant/lps/memberLevel/extendTime/templateDownload`, // 会籍延期=获取会员级别延期模板
  templateUpload: `${rezenPrefix}/merchant/lps/memberLevel/extendTime/templateUpload`, // 会籍延期=上传会员级别延期模板
  getCancelMemberText:`${rezenPrefix}/merchant/lps/member/getCancelMemberText`, //获取注销弹窗展示文案窗口
  cancelMemberSmsSend:`${rezenPrefix}/merchant/lps/member/cancelMemberSmsSend`, //发送注销短信验证码接口
  cancelMember:`${rezenPrefix}/merchant/lps/member/cancelMember`, //注销接口
  queryLpsMemberDel:`${rezenPrefix}/merchant/lps/member/queryLpsMemberDel`, //获取注销会员list
  getLpsMemberDel:`${rezenPrefix}/merchant/lps/member/getLpsMemberDel`, //获取注销会员详情
  getLpsMemberDelDetail:`${rezenPrefix}/merchant/lps/member/getLpsMemberDelDetail`,//注销明细
  setBookingTags: `${rezenPrefix}/member/tag/setBookingTags`, // 设置会员风控标签
  getEntityRiskList: `${rezenPrefix}/baize/risk/getEntityRiskList`, // 查询可疑用户列表
  realInterceptInfoList: `${rezenPrefix}/baize/risk/realInterceptInfoList`, // 真实拦截信息列表
  virtualInterceptInfoList: `${rezenPrefix}/baize/risk/virtualInterceptInfoList`, // 虚拟拦截信息列表
  getEntityRiskDetail: `${rezenPrefix}/baize/risk/getEntityRiskDetail`, // 查看可疑用户详情
  validateName: `${rezenPrefix}/merchant/user/validateName`,
  getUserRepurchaseOwnRecordList: `${rezenPrefix}/promotion/repurchase/getUserRepurchaseOwnRecordList`, // 复购领取记录
  getUnselectedHotelListForActivityCoupon: `${rezenPrefix}/merchant/product/activity/coupon/getUnselectedHotelListForActivityCoupon`,
  getSuitableHotelListByInputBox: `${rezenPrefix}/merchant/product/activity/coupon/getSuitableHotelListByInputBox`
};

// api映射关系
export default {
  ...crsApiMapping,
  ...rezenApiMapping,
};
